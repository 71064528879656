<template>
  <div class="floors">
    <v-alert
      dismissible
      elevation="10"
      outlined
      text
      type="error"
      transition="scale-transition"
      v-model="alert.visible"
      ><span v-html="alert.text">{{ alert.text }}</span></v-alert
    >
    <v-data-table
      :headers="headers"
      :items="units"
      hide-default-footer
      disable-pagination
      :no-data-text="noData"
      v-sortable-data-table
      @sorted="saveOrder"
      class="elevation-1"
      show-expand
      single-expand
    >
      <template v-slot:top>
        <v-toolbar elevation="1" height="200">
          <v-dialog v-model="dialog" max-width="500px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-container>
                <v-spacer></v-spacer>
                <div class="text-center mb-5">
                  <v-btn
                    color="success"
                    rounded
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!floor"
                  >
                    <v-icon>fa-plus</v-icon>
                  </v-btn>
                  <div class="caption">Agregar Unidad</div>
                </div>
                <v-spacer></v-spacer>
                <v-select
                  v-model="floor"
                  :items="floors"
                  label="Planta"
                  :color="whiteLabel.colors.primary"
                  :item-color="whiteLabel.colors.primary"
                  item-text="name"
                  item-value="id"
                  no-data-text="No existen plantas, debe crear una primero."
                  filled
                  dense
                ></v-select>
              </v-container>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-form ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <div class="text-center pb-3">
                          Independiente / Vinculada
                          <v-btn
                            icon
                            :color="whiteLabel.colors.primary"
                            @click="dialogLinked = true"
                          >
                            <v-icon>mdi-help-circle</v-icon>
                          </v-btn>
                        </div>
                        <v-layout row wrap justify-center v-if="!isLinkedBy">
                          <v-icon>fa-unlink</v-icon>
                          <v-switch
                            v-model="editedItem.unit_linked_id"
                            color="gray"
                            inset
                            class="pl-4"
                            @change="inputChanged"
                          >
                          </v-switch>
                          <v-icon>fa-link</v-icon>
                        </v-layout>
                        <v-layout row wrap justify-center>
                          <div class="caption font-weight-light pb-6">
                            <v-chip
                              close
                              dark
                              :color="whiteLabel.colors.primary"
                              close-icon="mdi-link"
                              @click="dialogLinked = true"
                              v-if="isLinkedBy"
                            >
                              No se permite encadenar vinculaciones
                            </v-chip>
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                    <v-row v-if="!editedItem.unit_linked_id">
                      <v-col cols="12">
                        <div class="text-center pb-3">Modo</div>
                        <v-layout row wrap justify-center>
                          <v-icon v-if="license && license.gate"
                            >mdi-phone</v-icon
                          >
                          <v-switch
                            v-if="license && license.gate"
                            v-model="editedItem.mode"
                            color="gray"
                            inset
                            class="pl-4"
                            @change="inputChanged"
                          >
                          </v-switch>
                          <v-icon>mdi-cellphone-wireless</v-icon>
                        </v-layout>
                        <v-layout row wrap justify-center>
                          <div class="caption font-weight-light pb-6">
                            Teléfono: recibe llamadas por telefonía convencional
                            en su telefono fijo o móvil.<br />
                            App móvil: recibe video-llamadas por internet en su
                            dispositivo móvil.
                          </div>
                        </v-layout>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-select
                        v-if="editedItem.unit_linked_id"
                        v-model="floor_linked"
                        :items="floors"
                        label="Planta a vincular"
                        item-text="name"
                        item-value="id"
                        no-data-text="No existen plantas, debe crear una primero."
                        filled
                        dense
                        :color="whiteLabel.colors.primary"
                        :item-color="whiteLabel.colors.primary"
                        :error="
                          editedItem.unit_linked_id != null &&
                          floor_linked == null
                        "
                        :rules="
                          editedItem.unit_linked_id ? requiredRuleLinked : []
                        "
                        @change="getUnitsForLink(editedItem.id)"
                      ></v-select>
                      <v-select
                        v-if="editedItem.unit_linked_id"
                        v-model="unit_linked"
                        :items="unitsForLink"
                        label="Unidad a Vincular"
                        item-text="name"
                        item-value="id"
                        no-data-text="No existen unidades a las cuales poder vincular en esta planta."
                        filled
                        dense
                        :color="whiteLabel.colors.primary"
                        :item-color="whiteLabel.colors.primary"
                        :error="unit_linked == null && floor_linked != null"
                        :rules="
                          editedItem.unit_linked_id ? requiredRuleLinked : []
                        "
                        @change="inputChanged"
                      ></v-select>
                    </v-row>
                    <v-row>
                      <v-col cols="7">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Unidad"
                          :color="whiteLabel.colors.primary"
                          :rules="requiredRuleName"
                          @input="inputChanged"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.alias"
                          label="Alias del buscador"
                          :color="whiteLabel.colors.primary"
                          @input="inputChanged"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.remarks"
                          label="Detalles adicionales durante el marcado"
                          :color="whiteLabel.colors.primary"
                          @input="inputChanged"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-form>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :color="whiteLabel.colors.primary"
                  text
                  @click="close"
                  :disabled="loading"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="save"
                  :loading="loading"
                  :disabled="!isValid"
                >
                  Guardar
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogLinked" max-width="450px" persistent>
            <v-card>
              <v-card-title>
                Funcionamiento de unidades vinculadas
              </v-card-title>
              <v-card-text>
                <br />
                - Unidad independiente: administra sus propios residentes.
                <br />
                - Unidad vinculada: vincula a los residentes de otra unidad.
                <br />
                - Vinculaciones encadenadas: Si otra/s unidad/es vinculan<br />a
                esta unidad, la misma no podrá configurarse como vinculada.
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="dialogLinked = false">
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px" persistent>
            <v-card>
              <v-card-title class="headline"
                >Desea eliminar la unidad "{{ editedItem.name }}"?</v-card-title
              ><v-card-text
                >ATENCIÓN: Se eliminarán todos los residentes de la unidad.<br />Esta
                operación puede demorar unos segundos.</v-card-text
              >

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :color="whiteLabel.colors.primary"
                  text
                  @click="closeDelete"
                  :disabled="loading"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="red darken-1"
                  text
                  @click="deleteItemConfirm"
                  :loading="loading"
                  >Eliminar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.dragRow="{}">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" dense class="sortHandler">
              mdi-drag-horizontal-variant
            </v-icon></template
          >
          <span>Reordenar</span>
        </v-tooltip>
      </template>

      <template v-slot:item.mode="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-if="item.unit_linked_id"
              class="pl-1"
              dense
              >fa-link</v-icon
            ></template
          >
          <span>Unidad vinculada</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-if="!item.unit_linked_id && item.mode"
              class="pl-1"
              dense
              >mdi-cellphone-wireless</v-icon
            ></template
          >
          <span>Atiende por dispositivo</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              v-if="!item.unit_linked_id && !item.mode"
              dense
              class="pl-1"
            >
              mdi-phone
            </v-icon></template
          >
          <span>Atiende por teléfono</span>
        </v-tooltip>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" v-if="item.unit_linked_id == null">
          Alias: {{ item.alias }}
          <br />
          Detalles de marcado: {{ item.remarks }}
          <br />
          Unidad independiente (no vinculada)
        </td>
        <td :colspan="headers.length" v-if="item.unit_linked_id != null">
          Alias: {{ item.alias }}
          <br />
          Detalles de marcado: {{ item.remarks }}
          <br />
          Unidad vinculada con {{ item.unit_linked_floor_name }} -
          {{ item.unit_linked_name }}
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon></template
          >
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-3"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon></template
          >
          <span>Eliminar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-3"
              v-if="item.unit_linked_id == null"
              @click="
                $router.push({
                  name: 'residents',
                  params: {
                    initial_floor: floor,
                    initial_unit: item.id,
                    initial_unit_mode: item.mode,
                  },
                })
              "
            >
              fa-user
            </v-icon></template
          >
          <span>Ir a Residentes de la Unidad</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-2"
              v-if="item.unit_linked_id != null"
            >
              fa-link
            </v-icon></template
          >
          <span>Unidad vinculada</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              @click="
                $router.push({
                  name: 'logs',
                  params: {
                    initial_floor: floor,
                    initial_unit: item.id,
                  },
                })
              "
            >
              mdi-history
            </v-icon>
          </template>
          <span>Ir a Registros de la Unidad</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { db, ff, firebase } from "@/fb";
import { mapGetters } from "vuex";
import Sortable from "sortablejs";

export default {
  data: () => ({
    loading: false,
    floor: null,
    floors: [],
    units: [],
    license__: {
      maxUnits: 0,
      doors: 0,
      rfid: false,
      video: false,
    },
    createdUnits: 0,
    snackbar: {
      visible: false,
      timeout: 2000,
      text: "",
      color: "",
    },
    alert: {
      visible: false,
      text: null,
    },
    dialog: false,
    dialogLinked: false,
    dialogDelete: false,
    headers: [
      {
        text: "",
        align: "start",
        sortable: false,
        value: "dragRow",
        width: "1%",
      },
      { text: "Unidad", align: "start", sortable: false, value: "name" },
      { text: "Modo", value: "mode", sortable: false },
      { text: "Más", value: "data-table-expand", sortable: false },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      id: "",
      mode: false,
      alias: "",
      remarks: "",
      previous_mode: false,
      unit_linked_id: false,
      unit_linked_name: null,
      unit_linked_floor_id: null,
      unit_linked_floor_name: null,
    },
    defaultItem: {
      name: "",
      id: null,
      mode: true,
      alias: "",
      remarks: "",
      unit_linked_id: false,
      unit_linked_name: null,
      unit_linked_floor_id: null,
      unit_linked_floor_name: null,
    },
    noData: "Seleccione planta",
    requiredRuleName: [(v) => !!v || "El Nombre es requerido"],
    requiredRuleLinked: [
      (v) => !!v || "La Planta y Unidad a vincular son requeridas.",
    ],
    isValid: false,
    isLinkedBy: false,
    unitForLink: null,
    unitsForLink: [],
    floor_linked: null,
    unit_linked: null,
  }),

  props: ["initial_floor"],

  mounted() {
    this.getLicenseInfo();
    this.getFloors();
    this.floor = this.initial_floor;
  },

  computed: {
    ...mapGetters({
      user: "user",
      license: "license",
      whiteLabel: "whiteLabel",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Unidad" : "Editar Unidad";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    floor() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando unidades ...";
      this.snackbar.color = "success";
      this.getUnits();
    },
  },

  methods: {
    async getFloors() {
      try {
        await db
          .collection("buildings/" + this.user.data.building + "/floors")
          .get()
          .then((floors) => {
            this.floors = [];
            let floor_positions = null;

            db.doc("buildings/" + this.user.data.building)
              .get()
              .then((floor) => {
                floor_positions = floor.data().floor_positions;

                let unsorted_floors = [];
                floors.forEach((floor) => {
                  unsorted_floors.push(floor);
                });

                floor_positions.forEach((floor) => {
                  let f = unsorted_floors.find((f) => f.id === floor);
                  this.floors.push({ name: f.data().name, id: f.id });
                });
              });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getUnits() {
      try {
        await db
          .collection(
            "buildings/" +
              this.user.data.building +
              "/floors/" +
              this.floor +
              "/apartments"
          )
          .get()
          .then((units) => {
            this.units = [];
            let unit_positions = null;

            if (!units.empty) {
              db.doc(
                "buildings/" + this.user.data.building + "/floors/" + this.floor
              )
                .get()
                .then((floor) => {
                  unit_positions = floor.data().apartment_positions;
                  let unsorted_units = [];
                  units.forEach((unit) => {
                    unsorted_units.push(unit);
                  });
                  unit_positions.forEach((unit) => {
                    let u = unsorted_units.find((u) => u.id === unit);
                    if (u.data().linked_id != null) {
                      db.collectionGroup("apartments")
                        .where("build_id", "==", this.user.data.building)
                        .get()
                        .then((units) => {
                          units.forEach((linked_unit) => {
                            if (linked_unit.id == u.data().linked_id) {
                              db.doc(
                                "buildings/" +
                                  this.user.data.building +
                                  "/floors/" +
                                  linked_unit.data().floor_id
                              )
                                .get()
                                .then((linked_floor) => {
                                  this.units.push({
                                    name: u.data().name,
                                    id: u.id,
                                    mode: u.data().mode,
                                    alias: u.data().alias,
                                    remarks: u.data().remarks,
                                    previous_mode: u.data().mode,
                                    unit_linked_id: u.data().linked_id
                                      ? u.data().linked_id
                                      : null,
                                    unit_linked_name: linked_unit.data().name,
                                    unit_linked_floor_name: linked_floor.data()
                                      .name,
                                    unit_linked_floor_id: linked_floor.id,
                                  });
                                });
                            }
                          });
                        });
                    } else {
                      this.units.push({
                        name: u.data().name,
                        id: u.id,
                        mode: u.data().mode,
                        alias: u.data().alias,
                        remarks: u.data().remarks,
                        previous_mode: u.data().mode,
                        unit_linked_id: u.data().linked_id
                          ? u.data().linked_id
                          : null,
                        unit_linked_name: null,
                        unit_linked_floor_name: null,
                        unit_linked_floor_id: null,
                      });
                    }
                  });
                });
            } else {
              if (this.floor) {
                this.noData = "No existen unidades para esta planta";
              }
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getLicenseInfo() {
      if (this.license && this.license.video) {
        this.mode = true;
        this.editedItem.mode = true;
      } else {
        this.mode = false;
        this.editedItem.mode = false;
      }
    },

    async getCreatedUnits() {
      let b = await db.doc("buildings/" + this.user.data.building).get();
      return b.data().total_units;
    },

    inputChanged() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.unit_linked_id) {
          if (this.floor_linked != null && this.unit_linked != null) {
            this.isValid = true;
          } else {
            this.isValid = false;
          }
        } else {
          this.isValid = true;
        }
      } else {
        this.isValid = false;
      }
    },

    editItem(item) {
      this.isValid = false;
      this.editedIndex = this.units.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.isLinkedByByAnother(item.id);

      this.floor_linked = item.unit_linked_floor_id;
      this.getUnitsForLink(item.id);
      this.unit_linked = item.unit_linked_id;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.units.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;
      this.snackbar.visible = true;
      this.snackbar.text = "Eliminando unidad ...";
      this.snackbar.color = "success";

      const data = { floor_id: this.floor, apartment_id: this.editedItem.id };
      ff.httpsCallable("deleteApartment")(data)
        .then((result) => {
          if (!result.data.error) {
            this.snackbar.visible = true;
            this.snackbar.text = "Unidad eliminada...";
            this.snackbar.color = "success";
            this.units.splice(this.editedIndex, 1);
          } else {
            this.snackbar.visible = true;
            this.snackbar.text =
              "Hubo un error al eliminar la unidad. Se canceló la operación.";
            this.snackbar.color = "error";
            console.log(
              "error deleting apartment",
              result.data.error.code + " - " + result.data.error.message
            );
          }
          this.loading = false;
          this.closeDelete();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.isValid = false;
      this.isLinkedBy = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.floor_linked = null;
        this.unit_linked = null;
        this.unitForLink = null;
        this.unitsForLink = [];
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      this.loading = true;

      this.editedItem.unit_linked_id = this.editedItem.unit_linked_id
        ? this.unit_linked
        : null;
      this.editedItem.unit_linked_name = this.unitsForLink.find(
        (f) => f.id === this.unit_linked
      )
        ? this.unitsForLink.find((f) => f.id === this.unit_linked).name
        : "";
      this.editedItem.unit_linked_floor_name = this.floors.find(
        (f) => f.id === this.floor_linked
      )
        ? this.floors.find((f) => f.id === this.floor_linked).name
        : "";
      this.editedItem.unit_linked_floor_id = this.floor_linked
        ? this.floor_linked
        : "";

      let unit = {
        name: this.editedItem.name,
        mode: this.editedItem.mode,
        alias: this.editedItem.alias,
        remarks: this.editedItem.remarks,
        linked_id: this.editedItem.unit_linked_id,
      };

      // Edicion
      if (this.editedIndex > -1) {
        if (
          this.editedItem.mode == true &&
          this.editedItem.previous_mode == false
        ) {
          // Paso de modo audio a modo video
          const data = {
            floor_id: this.floor,
            apartment_id: this.editedItem.id,
          };
          ff.httpsCallable("updateApartmentMode")(data)
            .then((result) => {
              if (!result.data.error) {
                let id = this.editedItem.id;
                db.doc(
                  "buildings/" +
                    this.user.data.building +
                    "/floors/" +
                    this.floor +
                    "/apartments/" +
                    id
                )
                  .update(unit)
                  .then(() => {
                    this.snackbar.visible = true;
                    this.snackbar.text = "Cambios guardados...";
                    this.snackbar.color = "success";
                    this.loading = false;
                    Object.assign(
                      this.units[this.editedIndex],
                      this.editedItem
                    );
                    this.close();
                  })
                  .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.close();
                  });
              } else {
                this.snackbar.visible = true;
                this.snackbar.text =
                  "El cambio de unidad a modo video no fue posible. Se canceló la operación.";
                this.snackbar.color = "error";
                console.log(
                  "error updating apartment mode",
                  result.data.error.code + " - " + result.data.error.message
                );
                this.loading = false;
                this.close();
              }
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              this.close();
            });
        } else {
          // No paso a modo video
          let id = this.editedItem.id;
          db.doc(
            "buildings/" +
              this.user.data.building +
              "/floors/" +
              this.floor +
              "/apartments/" +
              id
          )
            .update(unit)
            .then(() => {
              this.snackbar.visible = true;
              this.snackbar.text = "Cambios guardados...";
              this.snackbar.color = "success";
              this.loading = false;
              this.close();
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              this.close();
            });
          this.loading = false;
          Object.assign(this.units[this.editedIndex], this.editedItem);
          this.close();
        }
      } else {
        // Creacion
        this.getCreatedUnits().then((createdUnits) => {
          if (createdUnits < this.license.maxUnits) {
            unit.build_id = this.user.data.building;
            unit.floor_id = this.floor;
            unit.contact_positions = [];
            db.collection(
              "buildings/" +
                this.user.data.building +
                "/floors/" +
                this.floor +
                "/apartments/"
            )
              .add(unit)
              .then((createdUnit) => {
                db.doc(
                  "buildings/" +
                    this.user.data.building +
                    "/floors/" +
                    this.floor
                )
                  .update({
                    apartment_positions: firebase.firestore.FieldValue.arrayUnion(
                      createdUnit.id
                    ),
                  })
                  .then(() => {
                    this.incrementUnits();
                    this.snackbar.visible = true;
                    this.snackbar.text = "Unidad agregada ...";
                    this.snackbar.color = "success";

                    unit.id = createdUnit.id;
                    unit.unit_linked_id = this.editedItem.unit_linked_id
                      ? this.unit_linked
                      : null;
                    unit.unit_linked_name = this.unitsForLink.length
                      ? this.unitsForLink.find((f) => f.id === this.unit_linked)
                          .name
                      : "";
                    unit.unit_linked_floor_name = this.floor_linked
                      ? this.floors.find((f) => f.id === this.floor_linked).name
                      : "";
                    unit.unit_linked_floor_id = this.floor_linked
                      ? this.floor_linked
                      : "";

                    delete unit.build_id;
                    delete unit.floor_id;
                    this.units.push(unit);
                    this.close();
                    this.loading = false;
                  });
              });
          } else {
            this.loading = false;
            this.alert.visible = true;
            this.alert.text =
              `Se ha alcanzado la cantidad máxima de ` +
              this.license.maxUnits +
              ` unidades que permite su licencia.<br>
                        Contácte a <a target="_blank" href="https://tappbell.com#contacto">www.tappbell.com</a> para saber cómo obtener más.`;
            this.close();
          }
        });
      }
    },

    incrementUnits() {
      db.doc("buildings/" + this.user.data.building).update({
        total_units: firebase.firestore.FieldValue.increment(1),
      });
    },

    decrementUnits() {
      db.doc("buildings/" + this.user.data.building).update({
        total_units: firebase.firestore.FieldValue.increment(-1),
      });
    },

    saveOrder(event) {
      this.snackbar.visible = true;
      this.snackbar.text = "Orden de unidades actualizado ...";
      this.snackbar.color = "success";
      const movedItem = this.units.splice(event.oldIndex, 1)[0];
      this.units.splice(event.newIndex, 0, movedItem);
      const new_positions = [];
      this.units.forEach((floor) => {
        new_positions.push(floor.id);
      });
      db.doc("buildings/" + this.user.data.building + "/floors/" + this.floor)
        .update({ apartment_positions: new_positions })
        .then(() => {
          //
        });
    },

    async getUnitsForLink(current_unit) {
      this.unit_linked = null;
      this.isValid = false;
      try {
        await db.collectionGroup("apartments")
          .where("build_id", "==", this.user.data.building)
          .where("floor_id", "==", this.floor_linked)
          .get()
          .then((units) => {
            this.unitsForLink = [];
            units.forEach((unit) => {
              // Loop over all units and check if there is no linked_id field yet
              // Or linked_id is null
              // and unit id is not the current unit.
              if (
                (!Object.keys(unit.data()).includes("linked_id") ||
                  unit.data().linked_id == null) &&
                unit.id != current_unit
              ) {
                this.unitsForLink.push({ id: unit.id, name: unit.data().name });
              }
            });
          });
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    async isLinkedByByAnother(unit_id) {
      if (!unit_id) {
        this.isLinkedBy = false;
      }
      try {
        await db
          .collectionGroup("apartments")
          .where("build_id", "==", this.user.data.building)
          .where("linked_id", "==", unit_id)
          .get()
          .then((units) => {
            this.isLinkedBy = !units.empty;
          });
      } catch (error) {
        this.isLinkedBy = false;
        console.log(error);
        return [];
      }
    },
  },

  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          handle: ".sortHandler",
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.sortHandler {
  &:hover {
    cursor: ns-resize;
  }
}

.v-data-table__expanded {
  background-color: #e9e9e9 !important;
}
.v-data-table__expanded__row {
  background-color: red;
}

.v-data-table__expanded__content {
  box-shadow: unset;
}
</style>
